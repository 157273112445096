export const toastMessage = {
    imageSizeRestriction: 'TOAST.IMAGE_SIZE_RESTRICTION',
    somethingWentWrong: 'TOAST.SOMETHING_WENT_WRONG',
    workspaceUpdate: 'TOAST.WORKSPACE_UPDATE',
    workspaceSuccess: 'TOAST.WORKSPACE_SUCCESS',
    errorFetchingSubmissionData: 'TOAST.ERROR_FETCHING_SUBMISSION_DATA',
    formSettingUpdateError: 'TOAST.FORM_SETTING_UPDATE_ERROR',
    formDeleted: 'TOAST.FORMS_DELETED',
    formDeletionFail: 'TOAST.FORMS_DELETE_FAIL',
    formImportedSuccessfully: 'TOAST.FORM_IMPORTED_SUCCESSFULLY',
    couldNotImportedForm: 'TOAST.COULD_NOT_IMPORT_FORM',
    workspaceUrlCopied: 'TOAST.WORKSPACE_URL_COPIED',
    otpFieldNullError: 'TOAST.OTP_FIELD_NULL_ERROR',
    invitationDeleted: 'TOAST.INVITATION_DELETED',
    failedInvitationDeletion: 'TOAST.FAILED_INVITATION_DELETION',
    memberRemovedFromWorkspace: 'TOAST.MEMBER_REMOVED_FROM_WORKSPACE',
    failedToRemoveUser: 'TOAST.FAILED_TO_REMOVE_USER',
    invitationSent: 'TOAST.INVITATION_SENT',
    failedToSentEmail: 'TOAST.FAILED_TO_SEND_EMAIL',
    copied: 'TOAST.COPIED',
    customDomainUpdated: 'TOAST.CUSTOM_DOMAIN_UPDATED',
    customDomainDeletionError: 'TOAST.CUSTOM_DOMAIN_DELETION_ERROR',
    updated: 'TOAST.UPDATED_SUCCESSFULLY',
    invalidUrl: 'TOAST.INVALID_URL',
    formUrlCopied: 'TOAST.FORM_URL_COPIED',
    emailAlreadyExist: 'TOAST.EMAIL_ALREADY_EXIST',
    groupDeleted: 'TOAST.GROUP_DELETED',
    addedOnGroup: 'TOAST.ADDED_ON_GROUP',
    removeFromGroup: 'TOAST.REMOVE_FROM_GROUP',
    alreadyInGroup: 'TOAST.ALREADY_IN_GROUP',
    lastPersonOfGroup: 'TOAST.LAST_PERSON_IN_GROUP',
    removed: 'TOAST.REMOVED',
    accountDeletion: {
        success: 'TOAST.ACCOUNT_DELETION.SUCCESS',
        failed: 'TOAST.ACCOUNT_DELETION.FAILED'
    },
    formIsAlreadyOnGroup: 'TOAST.FORM_IS_ALREADY_ON_GROUP',
    csv: 'TOAST.CSV'
};
